#product-details {
  max-width: 100vw;
  display: grid;
  gap: 0;
  grid-auto-rows: max-content;
}

@media screen and (max-width: 600px) {
  #product-details {
    grid-template-columns: 6fr 6fr;
  }
  #product-gallery {
    grid-column: 1/3;
    grid-row: 1;
  }
  #product-title {
    grid-column: 1/3;
    grid-row: 2;
    margin-top: 5px;
  }
  #product-add {
    grid-column: 1/3;
    grid-row: 3;
  }
  #product-description {
    grid-column: 1/3;
    grid-row: 4;
  }
  #product-write-review {
    grid-column: 1/3;
    grid-row: 6;
  }
  #product-reviews {
    grid-column: 1/3;
    grid-row: 5;
  }
}

@media screen and (min-width: 600px) {
  #product-details {
    grid-template-columns: 6fr 6fr;
  }
  #product-gallery {
    grid-column: 1 / 3;
    grid-row: 1;
    margin: 0 2em 2em 0;
  }
  #product-title {
    grid-column: 1;
    grid-row: 2;
  }
  #product-add {
    grid-column: 2;
    grid-row: 2;
  }
  #product-description {
    grid-column: 1/3;
    grid-row: 3;
  }
  #product-write-review {
    grid-column: 2;
    grid-row: 4;
  }
  #product-reviews {
    grid-column: 1;
    grid-row: 4;
  }
}

@media screen and (min-width: 768px) {
  #product-details {
    grid-template-columns: 4fr 4fr 4fr;
  }
  #product-gallery {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  #product-title {
    grid-column: 3;
    grid-row: 1;
  }
  #product-add {
    grid-column: 3;
    grid-row: 2;
  }
  #product-description {
    grid-column: 2/4;
    grid-row: 3/5;
  }
  #product-write-review {
    grid-column: 1;
    grid-row: 4;
  }
  #product-reviews {
    grid-column: 1;
    grid-row: 3;
  }
}

@media screen and (min-width: 992px) {
  #product-details {
    grid-template-columns: 3fr 3fr 3fr 3fr;
    grid-auto-rows: minmax(100px, auto);
  }
  #product-gallery {
    grid-column: 1/3;
    grid-row: 1 / 3;
  }
  #product-title {
    grid-column: 3;
    grid-row: 1;
  }
  #product-add {
    grid-column: 4;
    grid-row: 1;
  }
  #product-description {
    grid-column: 3/5;
    grid-row: 2/4;
  }
  #product-write-review {
    grid-column: 2;
    grid-row: 3;
  }
  #product-reviews {
    grid-column: 1;
    grid-row: 3;
  }
}

.list-group-item p {
  margin-bottom: 0;
}
