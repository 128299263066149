@import "/index.css";

.promo-block {
  max-width: 100vw;
  display: grid;
  gap: 3px;
  grid-auto-rows: minmax(100px, auto);
}

.promo-block {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 0.8rem;
}
#promo-1 {
  grid-column: 1 / 2;
  grid-row: 1;
}
#promo-2 {
  grid-column: 1 / 2;
  grid-row: 2;
}
#promo-3 {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}
#promo-4 {
  grid-column: 4 / 5;
  grid-row: 1;
}
#promo-5 {
  grid-column: 4 / 5;
  grid-row: 2;
}
#promo-6 {
  grid-column: 5 / 6;
  grid-row: 1;
}
#promo-7 {
  grid-column: 5 / 6;
  grid-row: 2;
}
#promo-8 {
  grid-column: 6;
  grid-row: 1/3;
}
#promo-9 {
  grid-column: 6;
  grid-row: 2;
}

@media screen and (max-width: 768px) {
  .promo-block {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 0.6rem;
  }
  #promo-1 {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  #promo-2 {
    display: none;
  }
  #promo-3 {
    grid-column: 3 / 5;
    grid-row: 2 / 4;
  }
  #promo-4 {
    grid-column: 3;
    grid-row: 1;
  }
  #promo-5 {
    grid-column: 4;
    grid-row: 1;
  }
  #promo-6 {
    display: none;
  }
  #promo-7 {
    grid-column: 2;
    grid-row: 3;
  }
  #promo-8 {
    grid-column: 1;
    grid-row: 3;
  }
  #promo-9 {
    display: none;
  }
}

.promo-block div img {
  max-width: 100%;
  margin: auto;
}

.promo-block > div:hover img {
  transform: scale(1.1);
  transition: all 0.5s cubic-bezier(0, 0, 0.44, 1.18);
}

.overflow-hidden {
  overflow: hidden;
}

.promo-block-text {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  color: white;
  font-weight: 500;
  font-size: 1.8em;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  align-items: center;
  backdrop-filter: blur(3px);
  box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.03);
}

.promo-block-button-container {
  position: relative;
}

.promo-block-button-container:hover .promo-block-button {
  opacity: 1;
}

.promo-block-button {
  transition: all 0.5s;
  opacity: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 8px 0;
  text-align: center;
  z-index: 100;
  background-color: rgba(248, 7, 7, 0.6);
  color: white;
}

.promo-welcome-header {
  text-transform: uppercase;
  font-weight: 500;
}
