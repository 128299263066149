header {
  max-width: 1900px;
  margin: auto;
}

/* ------- LOGO ------- */
.logo {
  width: 70px;
}
@media (max-width: 992px) {
  .logo {
    width: 60px;
  }
}
@media (max-width: 768px) {
  .logo {
    width: 50px;
  }
}
@media (max-width: 992px) {
  #admin-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
}

/* ------- ADMIN-MENU ------- */
#admin-menu {
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0;
  margin: -5px;
  height: 32px;
}
#admin-menu a {
  margin: 0 5px;
  padding: 0 10px;
  background-color: green;
  color: white;
  border-radius: 0 0 30px 30px;
}

/* ------- MENU ------- */
.underlink {
  position: relative;
  color: #000;
  text-decoration: none;
}
.underlink:hover {
  color: #000;
}
.underlink:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.underlink:hover::before {
  visibility: visible;
  transform: scaleX(1);
}
