@import "./_cosmo.min.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

/* --breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px; */

html,
body,
#root {
  padding: 0;
  margin: 0;
}
main {
  min-height: 80vh;
  width: 100vw;
  max-width: 1900px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .jumbotron {
    padding: 1rem 1rem;
  }
}

.form-inline {
  display: flex;
  flex-flow: row nowrap;
}
.row {
  margin-right: 0;
  margin-left: 0;
}

.rating i {
  color: orange;
  font-size: 0.8em;
}

h1 {
  margin: 0.5em 0 0 0;
}
h2 {
  margin: 0.5em 0 0.5em 0;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  text-transform: uppercase;
  text-align: center;
}
h3 {
  text-transform: uppercase;
}
.custom-control {
  padding-left: 0;
}

.container {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

td:first-of-type,
th:first-of-type {
  padding-left: 10px;
}
.order-summary-table th {
  font-size: 0.8em;
  font-weight: normal;
  font-style: oblique;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding: 5px;
}

.carousel {
  height: 30vh;
  max-height: 30vh;
  overflow: hidden;
}
.carousel-caption {
  bottom: auto;
  top: 10px;
}

.form-control {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background-color: #9e9e9e0f !important;
  min-width: 85px;
}

.product-card-block {
  padding-right: 8px;
  padding-left: 8px;
}
.card.product-card {
  border: 0;
  overflow: hidden;
  display: block;
}
.card-body {
  padding: 0 0 10px 0;
}
.card-title {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.table thead th {
  border: 0;
}

.color-picture {
  display: inline-block;
  flex: 1 1 auto;
  margin-right: 5px;
}

.order-select {
  min-width: 120px;
}

button.image-gallery-icon {
  filter: none;
}

.image-gallery-right-nav,
.image-gallery-left-nav {
  transform: translateY(-50%) scale(0.5);
  padding: 0;
  background-color: rgb(0, 0, 0, 0.2);
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 100px;
  width: 100px;
}

.image-gallery-fullscreen-button {
  padding: 3px;
  top: 0;
  left: 0;
  bottom: auto;
}

.list-group-item p {
  margin-bottom: 0.5rem;
}

.checkboxImg {
  margin-top: -25px;
}

.col {
  padding-right: 0;
}

.img-example {
  width: 60%;
  height: 60%;
  margin: 20%;
  filter: opacity(0.3);
}
.img-card:hover {
  transform: scale(1.1);
  transition: all 0.5s cubic-bezier(0, 0, 0.44, 1.18);
}
.img-card-container {
  overflow: hidden;
}

.product-brand {
  text-align: center;
  margin-bottom: -15px;
}

.form-container {
  justify-content: center;
}

.nav-link {
  padding: 0.5rem 1rem;
}

.label-comment {
  font-size: 0.9em;
  color: gray;
  margin-top: -3px;
  margin-left: 10px;
  line-height: 0.9em;
}

.form-group {
  margin-bottom: 0;
}

.submenu {
  text-align: right;
}

.breadcrumb-item .nav-item .nav-link {
  padding: 0 0.6rem;
}

label {
  margin-top: 0.8rem;
  margin-bottom: 0;
}
