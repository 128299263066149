#rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-size: 1em;
}

#rating > input {
  display: none;
}

#rating > label {
  position: relative;
  width: 1em;
  font-size: 1rem;
  color: orange;
  cursor: pointer;
}

#rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

#rating > label:hover:before,
#rating > label:hover ~ label:before {
  opacity: 1 !important;
}

#rating > input:checked ~ label:before {
  opacity: 1;
}

/* #rating:hover > input:checked ~ label:before {
  opacity: 0.4;
} */
